import { RestService } from './../../core/rest.service';
import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, BsModalService } from 'ngx-bootstrap';
import { MessageService } from 'src/core/message/message.service';
import { CommunicationService } from 'src/services/communication.service';
import { AbstractService } from 'src/services/abstract.service';
declare const gapi: any;
@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
  providers: [AuthService, RestService]
})

export class LoginComponent implements OnInit  , OnDestroy {
  public localState = { value: '' };
  formGroup: FormGroup;

      // TO MAKE RESPONSIVE
    isCollapsed: boolean = true;
  lastUrl: any;
  public auth2: any;
  googleEmail: any;
  googleToken: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  loginRequired: string;
  loginMinLength: string;
  loginMaxLength: string;
  loginEmailInvalid: string;
  passwordRequired: string;
  passwordMaxLength: string;
  sendOtpFormGroup: FormGroup;
  oneTimePasswordFormGroup: FormGroup;
  isShowOtpFormGroup: boolean = false;
  isUserNotRegistered: boolean = false;
  isRegistrationRequired: boolean = false;
  registerCheckboxValue: boolean = false;
  @ViewChild('registerModal',  {static: false}) registerModal: ModalDirective;
  modalRef: any;
  conferenceShortName: string;
  eventShortName: string;
  constructor(private authService: AuthService, private route: ActivatedRoute , private router: Router, public modalService: BsModalService,
              public ngZone: NgZone, public cookieService: CookieService, public messagingService: MessageService,
              public translate: TranslateService, public communicationService:CommunicationService, private abstractService:AbstractService) {
                // if (this.cookieService.get('userId')) {
                //   this.router.navigate(['/home']);
                // } @TODO
                this.lastUrl =  this.cookieService.get('lastUrl');
                this.deleteAllCookies();
  this.conferenceShortName = this.route.snapshot.queryParamMap.get('conference') ? this.route.snapshot.queryParamMap.get('conference') : 'IEEE-9';
  this.eventShortName = this.route.snapshot.queryParamMap.get('event') ? this.route.snapshot.queryParamMap.get('event') : 'event3' ;
              }
  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
     }
  public ngOnInit() {
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ])
    });
    this.sendOtpFormGroup =  new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)      ])
      });
    this.oneTimePasswordFormGroup =  new FormGroup({
      otpContent: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50)
        ])
        });
    }

onSubmit(values) {
  if (this.formGroup.valid) {
  this.authService.login(values.Email, values.Password).subscribe((res) => {
  this.setCookieAndNavigate(res.response.userId , res.response.adminAuthority , res.response.userName,false);
  // this.posterPanelList(res.response.userId , res.response.adminAuthority , res.response.userName)
}, (err) => {
});
  } else {
    this.validateAllFormFields(this.formGroup);
  }
}

public posterPanelList(userId,adminAuthority,userName) {
  this.abstractService.getPosterPanelList().subscribe((res) => {
    var posterAdminAuthority = false;
    if(res.response.entries) {
      const reviewerList = res.response.entries;
      reviewerList.forEach(element => {
        if(element.shortName === userId) {
          posterAdminAuthority = true;
          this.setCookieAndNavigate(userId , adminAuthority , userName,posterAdminAuthority);
        } else {
          //  this.cookieService.set('posterAdmin', 'false' );
          this.setCookieAndNavigate(userId , adminAuthority , userName,posterAdminAuthority);
        }
      });
    } else {
      // this.cookieService.set('posterAdmin', 'false' );
      this.setCookieAndNavigate(userId , adminAuthority , userName,posterAdminAuthority);
    }
  });
 }

  onReset() {
    this.formGroup.reset();
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 

  goToForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  setCookieAndNavigate(userId , adminAuthority , userName, posterAdminAuthority) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
    this.communicationService.setPosterAdminAuthority(posterAdminAuthority);
    if (this.lastUrl) {
    this.router.navigateByUrl(this.lastUrl);
    this.cookieService.delete('lastUrl');
    } else {
      if(posterAdminAuthority) {
        this.router.navigate(['/poster/dashboard']);
      } else {
        if(adminAuthority) {
          this.router.navigate(['/home/abstractTasks']);
        } else {
          this.router.navigate(['/home/fullpapertasks']);
        }
       
      }
    }
  }

  ngOnDestroy() {
  if (this.modalRef !== undefined) {
    this.modalRef.hide();
  }
}
onOpenNotAbleToSignInModal(notAbleToSignInModal) {
this.modalRef = this.modalService.show(notAbleToSignInModal, this.config);
}
goToRegister() {
  this.router.navigate(['/register']);
}
public deleteAllCookies() {
  const cookies = document.cookie.split(';');
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== 'lastUrl') {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
  }
}
}
